import React from 'react';
import EventList from '../components/EventList';
import PageWrapper from '../components/PageWrapper';
import Helmet from 'react-helmet';
import BioLayout from '../components/BioLayout';

export default ({ data }) => {
  const bio = data.allGoogleSheetBioRow.edges;
  return (
    <PageWrapper>
      <BioLayout>
        <Helmet>
          <title>Wiebke Hahn</title>
        </Helmet>
        <h1>Wiebke Hahn</h1>
        <div className="intro">
          Kulturmanagerin und Projektentwicklerin
          <br />
          Kultur | Wissenschaft | Politik | Urbanes
          <br />
          Zuletzt bei{' '}
          <a href="https://twitter.com/wissimdialog">@wissimdialog</a>,{' '}
          <a href="https://twitter.com/KMMHamburg">@KMMHamburg</a>,{' '}
          <a href="https://twitter.com/boliviafes">@BoliviaFes</a>,{' '}
          <a href="https://twitter.com/colomboalemana">@colomboalemana</a>
          <br />
          <br />
          <a href="https://twitter.com/WiebkeHahn">Twitter</a>,{' '}
          <a href="https://www.xing.com/profile/Wiebke_Hahn6">Xing</a>
        </div>

        <EventList key="b" events={bio} />
      </BioLayout>
    </PageWrapper>
  );
};

export const query = graphql`
  query AboutQuery {
    allGoogleSheetBioRow {
      edges {
        node {
          date
          link
          description
        }
      }
    }
  }
`;
