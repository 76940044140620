import React from 'react';
import { map } from 'lodash';
import s from './style.module.css';

const EventList = ({ events }) => {
  return (
    <ul className={s.list}>
      {map(events, (event, i) => {
        return (
          <Event event={event} key={i}>
            {event.node.description}
          </Event>
        );
      })}
    </ul>
  );
};

function Event({ event }) {
  return (
    <li className={s.event}>
      {event.node.date && <h2 className={s.date}>{event.node.date}</h2>}
      <p className={s.description}>
        {event.node.description}{' '}
        {event.node.link && <a href={event.node.link}>mehr</a>}
      </p>
    </li>
  );
}

export default EventList;
