import React from 'react';
import s from './style.module.css';
import Logo from '../Logo';

export default ({ children }) => {
  return (
    <main className={s.main}>
      <Logo />
      <div className={s.content}>{children}</div>
    </main>
  );
};
